<template>
  <div class="imprint">
    <h1>Dienstanbieter im Sinne von § 6 TDG:</h1>
    <div>
      more! it-solutions GmbH<br><br>
      Aubachstraße 30<br><br>
      D 56410 Montabaur<br><br>
      Telefon: 02602 - 83 88 60<br><br>
      Geschäftsführung: Dipl. Inf. Thomas Franz<br><br>
      Registergericht: Montabaur, HR B 25671<br><br>
      Steuer-Nummer: 30/670/13822<br><br>
    </div>

    <h1>Handelsmarken / Lizenzrechte</h1>
    <div>
      Die Markennamen "more!", "more! plus" und "more! rubin" sind eingetragene Warenzeichen der Firma "more! software GmbH & Co. KG". "OParl" ist ein Spezifikationsprojekt der OKF Deutschland e.V. und der Vitako e.V.
    </div>

    <h1>Aktualität</h1>
    <div>
      Die auf den Websites abgebildeten Eingabemasken sowie Programm-/Ablaufbeschreibungen und Programmdemonstrationen entsprechen dem zum Zeitpunkt der Erstellung dieser Seiten aktuellen Programmstand von more! rubin. Änderungen und Aktualisierungen – auch gegenüber Leistungsbeschreibungen und genannten technischen Anforderungen - bleiben vorbehalten.
    </div>

    <h1>Haftung</h1>
    <div>
      Auf diesen Internet-Seiten finden Sie auch Links zu anderen Seiten im Internet. Die Firma more! it-solutions GmbH übernimmt keine Verantwortung für die Inhalte von Websites, die über Links unserer Seiten erreicht werden können. Es erfolgte zwar eine stichprobenartige Prüfung der Websites, zu denen Links geschaltet wurden, eine kontinuierliche Prüfung der Inhalte ist jedoch weder beabsichtigt noch möglich. Wir distanzieren uns ausdrücklich von allen Inhalten, die möglicherweise straf- oder haftungsrechtlich relevant sind oder gegen die guten Sitten verstoßen.
    </div>

    <h1>Urheberrechtshinweise</h1>
    <div>
      Alle Inhalte (inkl. Bildern, Grafiken) dieser Internetseite sind urheberrechtlich geschützt. Das Urheberrecht liegt bei den jeweiligen Autoren bzw. Erstellern. Jegliche Arten der Verwertung (inkl. die Weitergabe von Inhalten in Datenbanken oder anderen elektronischen Medien und Systemen) bedürfen der schriftlichen Zustimmung der jeweiligen Autoren bzw. Ersteller. Das unerlaubte Kopieren bzw. Speichern aller bereitgestellten Informationen und Bildern auf diesen Internetseiten ist nicht gestattet.
    </div>

    <h1>Haftung für Inhalte</h1>
    <div>
      Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
    </div>

    <h1>Rechtswirksamkeit</h1>
    <div>
      Sofern dieser Text oder Teile daraus der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen, bleiben die übrigen Teile dieses Textes davon unberührt. Die ungültigen Textteile sind in diesem Fall ihrem Sinn nach zu verstehen.
    </div>
  </div>
</template>

<style scoped lang="scss">
.imprint {
  max-width: 800px;
  margin: auto;
}
</style>
